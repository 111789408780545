import React from 'react';
import { RowError, SimpleProductExt } from '../eve-quick-order';
import { IAdvancedNoteEntity, IManufacturerCodeResponseEntity } from '../../../actions/DataServiceEntities.g';
import { SupersessionKey } from '../../../themes/gardner-theme/models/SupersessionKey';
import { Tooltip } from 'reactstrap';

export interface IEveQuickOrderRowResources {
    defaultManufacturerCodeText: string;
    deleteText: string;
    productEligibilityText: string;
    supersededProductText: string;
}

interface IEveQuickOrderRowProps {
    id: number;
    onDelete: (id: number) => void;
    onRowChange: (id: number, value: string, quantity: string, manufacturerCode: string) => void;
    onAddRows: () => void;
    value: string;
    quantity: string;
    product: SimpleProductExt | null;
    availableProducts: SimpleProductExt[] | null;
    currencyFormatter: (price: string | number, currencyCode?: string | undefined) => string;
    isLastRow: boolean;
    rowError: RowError;
    onSelectedProductChange: (id: number, selectedProductId: number) => void;
    resources: IEveQuickOrderRowResources;
    manufacturerCodes: IManufacturerCodeResponseEntity[];
    manufacturerCode: string;
    advancedNotes: IAdvancedNoteEntity[];
}

export const EveQuickOrderRow: React.FC<IEveQuickOrderRowProps> = props => {
    const {
        id,
        onDelete,
        value,
        quantity,
        onRowChange,
        product,
        currencyFormatter,
        isLastRow,
        onAddRows,
        manufacturerCodes,
        resources: { defaultManufacturerCodeText, productEligibilityText, supersededProductText },
        rowError,
        manufacturerCode,
        advancedNotes
    } = props;

    /**
     * Emits the row delete function
     */
    const handleRowDelete = () => onDelete(id);

    /**
     * Emits row change when changing value
     * @param e
     */
    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onRowChange(id, e.target.value, quantity, manufacturerCode);
    };

    /**
     * Emits row change when changing quantity
     * @param e
     */
    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onRowChange(id, value, e.target.value, manufacturerCode);
    };

    /**
     * Emits add row function
     */
    const addRows = () => {
        onAddRows();
    };

    const [isToolTipOpen, setIsToolTipOpen] = React.useState<boolean>(false);

    /**
     * Toggle which updates the tooltip state
     * @returns void
     */
    const toggleTooltip = () => setIsToolTipOpen(!isToolTipOpen);

    const handleManufacturerCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onRowChange(id, value, quantity, e.target.value);
    };
    /**
     * Gets the row message to display based on row state
     */
    const getRowStatusMessage = (product: SimpleProductExt | null): React.ReactElement | null => {
        switch (rowError) {
            case RowError.None:
                if (product) {
                    const supersessionName = product.ExtensionProperties?.find(ep => ep.Key === SupersessionKey.SupersededName)?.Value
                        ?.StringValue;

                    return (
                        <>
                            <p>
                                {`${product.Name} 
                                    ${supersessionName ? supersededProductText.replace('{0}', supersessionName) : ''}` || ''}
                            </p>
                            {advancedNotes ? mapNotes(advancedNotes) : ''}
                        </>
                    );
                }
                break;
            case RowError.InvalidQuantity:
                return <p>Invalid Quantity</p>;
            case RowError.ProductNotFound:
                return <p>Product Not Found</p>;
            case RowError.MultipleChoicesAvailable:
                return <p>Please select a manufacturer</p>;
            case RowError.NotEligbile:
                return <p>{productEligibilityText}</p>;
            case RowError.Unavailable:
                return <p>Not Available</p>;
        }

        return null;
    };

    /**
     * Turn array of IAdvancedNoteEntity into JSX element with Tooltip
     *
     * @param {IAdvancedNoteEntity[]} notes the array of notes to display in the tooltip
     * @return {*}
     */
    function mapNotes(notes: IAdvancedNoteEntity[]) {
        if (notes && notes.length && notes[0].ItemId) {
            return (
                <>
                    <a
                        href='javascript:void(0)'
                        data-toggle='tooltip'
                        className='readMore-toolTip'
                        id={`tooltip-${removeSpacesFromItemId(notes[0].ItemId)}`}
                    >
                        Read Notes
                    </a>
                    <Tooltip
                        className='qo-tooltip'
                        placement='right'
                        isOpen={isToolTipOpen}
                        target={`tooltip-${removeSpacesFromItemId(notes[0].ItemId)}`}
                        toggle={toggleTooltip}
                    >
                        <ul className={'advancedNotes'}>
                            {advancedNotes.map(note => (
                                <li key={note.RecId}>{note.TextValue}</li>
                            ))}
                        </ul>
                    </Tooltip>
                </>
            );
        } else {
            return null;
        }
    }

    const removeSpacesFromItemId = (itemId: string): string => itemId.replace(/\s/g, '');

    const getAvailability = () => product?.ExtensionProperties?.find(ep => ep.Key === SupersessionKey.Inventory)?.Value?.StringValue || '0';

    return (
        <>
            <div className='q-col q-col-1'>
                <select
                    name='manufacturer-codes-select'
                    id='manufacturer-codes-select'
                    className={`manufacturer-codes-select__dropdown`}
                    value={manufacturerCode}
                    onChange={handleManufacturerCodeChange}
                >
                    {!manufacturerCode && <option value={''}>{defaultManufacturerCodeText}</option>}
                    {manufacturerCodes &&
                        manufacturerCodes.length > 0 &&
                        manufacturerCodes.map(code => (
                            <option value={code.GDNManufacturerCode} key={code.GDNManufacturerCode}>
                                {code.GDNManufacturerCode} - {code.Description}
                            </option>
                        ))}
                </select>
            </div>
            <div className='q-col q-col-2'>
                <div className={'qo-input-group'}>
                    <span className='qo-input'>
                        <input
                            type='text'
                            className='qo-input__input'
                            id={'ipt'}
                            aria-label={'Product'}
                            value={value}
                            onChange={handleValueChange}
                            onFocus={(isLastRow && addRows) || undefined}
                        />
                    </span>
                </div>
            </div>
            <div className='q-col q-col-3'>
                <div className={'qo-input-group'}>
                    <span className='qo-input'>
                        <input
                            type='text'
                            className='qo-input__input'
                            id={'ipt'}
                            aria-label={'Quantity'}
                            value={quantity}
                            onChange={handleQuantityChange}
                            onFocus={(isLastRow && addRows) || undefined}
                        />
                    </span>
                </div>
            </div>
            <div className='q-col q-col-4'>{getRowStatusMessage(product)}</div>
            <div className='q-col q-col-5'>{product && <p>{currencyFormatter(product.Price)}</p>}</div>
            <div className='q-col q-col-6'>{product && <p>{getAvailability()}</p>}</div>
            <div className='q-col q-col-7'>
                <button className={'qo-button'} onClick={handleRowDelete}>
                    <span className='q-col-7__delete-icon'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            fill='currentColor'
                            className='bi bi-trash'
                            viewBox='0 0 16 16'
                        >
                            <path d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z' />
                            <path
                                fillRule='evenodd'
                                d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'
                            />
                        </svg>
                    </span>
                    {/* <span className='q-col-6__delete-text'>{deleteText}</span> */}
                </button>
            </div>
        </>
    );
};
