/* eslint-disable react/jsx-no-bind */
import React from 'react';
import Table from 'reactstrap/lib/Table';
import { IBackOrderListEntity } from '../../../actions/DataServiceEntities.g';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { ICoreContext, IDateFormatOptions } from '@msdyn365-commerce/core';
import { format } from '@msdyn365-commerce-modules/utilities';

export interface IBackOrderTableComponentResources {
    backOrderListTitleText: string;
    backOrderListItemsText: string;
    orderNumberHeadingText: string;
    poNumberHeadingText: string;
    mfrHeadingText: string;
    partNumberHeadingText: string;
    deliveryTypeHeadingText: string;
    qtyHeadingText: string;
    totalHeadingText: string;
    noBackOrdersMessageText: string;
    estShipDateHeadingText: string;
}

interface IBackOrderTableComponentProps {
    backOrders: IBackOrderListEntity[];
    resources: IBackOrderTableComponentResources;
    totalCount: number;
    backOrderEtaBuffer: number;
    ctx: ICoreContext;
    sortingDropdown: JSX.Element;
    sortOrderButton: JSX.Element;
    currencyFormatter: (price: string | number, currencyCode?: string | undefined) => string;
}

export const BackOrderTableComponent: React.FC<IBackOrderTableComponentProps> = props => {
    const { backOrders, resources, totalCount, backOrderEtaBuffer, sortOrderButton, sortingDropdown, currencyFormatter, ctx } = props;
    const {
        backOrderListItemsText,
        orderNumberHeadingText,
        poNumberHeadingText,
        mfrHeadingText,
        partNumberHeadingText,
        deliveryTypeHeadingText,
        qtyHeadingText,
        totalHeadingText,
        noBackOrdersMessageText,
        estShipDateHeadingText
    } = resources;

    const [outerWidth, setOuterWidth] = React.useState(0);

    React.useEffect(() => {
        //Set initial outerWidth
        setOuterWidth(window.outerWidth);
        //Resize window event handling
        window.removeEventListener('resize', () => setOuterWidth(window.outerWidth));
        window.addEventListener('resize', () => setOuterWidth(window.outerWidth), { passive: true });
        return window.removeEventListener('resize', () => setOuterWidth(window.outerWidth));
    });

    if (outerWidth === 0) {
        return null;
    }

    // console.log(props);

    return (
        <div className={`back-order-results`}>
            {outerWidth > 768 ? (
                <>
                    <div className={'back-order-results__info'}>
                        {totalCount > 0 && (
                            <div className='back-order-results__info-sort'>
                                {sortingDropdown}
                                {sortOrderButton}
                            </div>
                        )}
                        {format(backOrderListItemsText, totalCount)}
                    </div>
                    <Table className={'back-order-results__table'}>
                        <thead>
                            <tr>
                                <th>{orderNumberHeadingText}</th>
                                <th>{poNumberHeadingText}</th>
                                <th>{deliveryTypeHeadingText}</th>
                                <th>{mfrHeadingText}</th>
                                <th>{partNumberHeadingText}</th>
                                <th>{qtyHeadingText}</th>
                                <th>{totalHeadingText}</th>
                                <th>{estShipDateHeadingText}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {backOrders.length > 0 ? (
                                backOrders.map((order, index) => {
                                    const deliveryType = order.DeliveryType === 'None' ? 'Standard' : order.DeliveryType;
                                    const shipDateString = order.ShippingDate?.toString() ? order.ShippingDate?.toString() : '';
                                    const startIndex = shipDateString.indexOf('(') + 1;
                                    const endIndex = shipDateString.indexOf(')');
                                    const result = parseFloat(shipDateString.substring(startIndex, endIndex));
                                    const estShipDateRes = result ? new Date(result) : new Date();
                                    estShipDateRes.setDate(estShipDateRes.getDate());
                                    return (
                                        <tr key={`backorder-${index}`}>
                                            <td>{order.SalesId}</td>
                                            <td>{order.PurchaseOrderNumber}</td>
                                            <td>{deliveryType}</td>
                                            <td>{order.ManufacturerNumber}</td>
                                            <td>{order.ItemId}</td>
                                            <td>{order.Quantity}</td>
                                            <td>{currencyFormatter(order.Total || 0)}</td>
                                            <td>{formatDate(ctx, estShipDateRes, backOrderEtaBuffer)}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className='back-order-results__table--no-backorders'>
                                    <td>{noBackOrdersMessageText}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </>
            ) : (
                <ListGroup className={'back-order-results__list'}>
                    <div>
                        <h5>{format(backOrderListItemsText, totalCount)}</h5>
                        {backOrders.map((order, index) => {
                            const deliveryType = order.DeliveryType === 'None' ? 'Standard' : order.DeliveryType;
                            const shipDateString = order.ShippingDate?.toString() ? order.ShippingDate?.toString() : '';
                            const startIndex = shipDateString.indexOf('(') + 1;
                            const endIndex = shipDateString.indexOf(')');
                            const result = parseFloat(shipDateString.substring(startIndex, endIndex));
                            const estShipDateRes = result ? new Date(result) : new Date();
                            estShipDateRes.setDate(estShipDateRes.getDate());
                            return (
                                <ListGroupItem key={`backorder-${index}`}>
                                    <ListGroupItemHeading>
                                        <div className={'back-order-results__list-double-data'}>
                                            <span className={'back-order-results__list-double-data--id'}>{order.ItemId}</span>
                                            <span className={'back-order-results__list-double-data--po'}>{deliveryType}</span>
                                        </div>
                                    </ListGroupItemHeading>
                                    <ListGroupItemText>
                                        <div className={'back-order-results__list-double-data'}>
                                            <span className={'back-order-results__list-double-data--mfr'}>
                                                <b>{`${mfrHeadingText} : `}</b>
                                                {order.ManufacturerNumber}
                                            </span>
                                            <span className={'back-order-results__list-double-data--est'}>
                                                <b>{`${estShipDateHeadingText} : `}</b>
                                                {formatDate(ctx, estShipDateRes, backOrderEtaBuffer)}
                                            </span>
                                        </div>
                                    </ListGroupItemText>
                                    <ListGroupItemText>
                                        <div className={'back-order-results__list-double-data'}>
                                            <span className={'back-order-results__list-double-data--id'}>{order.SalesId}</span>
                                            {order.PurchaseOrderNumber && (
                                                <span className={'back-order-results__list-double-data--po'}>
                                                    <b>{`${poNumberHeadingText} `}</b>
                                                    {order.PurchaseOrderNumber}
                                                </span>
                                            )}
                                        </div>
                                    </ListGroupItemText>
                                    <ListGroupItemText>
                                        <div className={'back-order-results__list-double-data'}>
                                            <span className={'back-order-results__list-double-data--qty'}>
                                                <b>{`${qtyHeadingText} : `}</b>
                                                {order.Quantity}
                                            </span>
                                            <span className={'back-order-results__list-double-data--price'}>
                                                <b>{`${totalHeadingText} : `}</b>
                                                {currencyFormatter(order.Total || 0)}
                                            </span>
                                        </div>
                                    </ListGroupItemText>
                                </ListGroupItem>
                            );
                        })}
                    </div>
                </ListGroup>
            )}
        </div>
    );
};

/**
 * Formats date.
 * @param context - Core context.
 * @param date - Date to display.
 * @returns String value.
 */
const formatDate = (context: ICoreContext, date: Date | undefined, bufferInDays: number) => {
    if (date === undefined) {
        return '';
    }

    // If no date find f&O returns default date.
    const defaultDate = new Date(-2208988790000);
    if (
        date.getFullYear() === defaultDate.getFullYear() &&
        date.getMonth() === defaultDate.getMonth() &&
        date.getDate() === defaultDate.getDate()
    ) {
        return 'N/A';
    }

    const dateOptions: IDateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
    date.setDate(date.getDate() + bufferInDays);
    return context.cultureFormatter.formatDate(date, dateOptions);
};
